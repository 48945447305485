import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Search from 'media/search.svg?svgr-webpack'
import Logo from 'js/components/Logo'
import {
  adjustAndroidSmartBannerAppToken,
  adjustIosSmartBannerAppToken,
} from 'js/config'
import HamburgerMenu from 'js/pages/LandingPages/components/HamburgerMenu'

function SearchField() {
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  return (
    <form
      className="relative"
      onSubmit={(e) => {
        e.preventDefault()

        if (!query) return

        navigate(`/nutrition-explained/search/${query}`)
      }}
    >
      <input
        className="h-32 rounded-full border-1 border-grey-lightest px-12 py-4 outline-none"
        value={query}
        onChange={(e) => {
          setQuery(e.currentTarget.value)
        }}
      />
      <Search className="absolute right-0 top-1/2 mr-8 h-16 w-16 -translate-y-1/2 transform text-white" />
    </form>
  )
}

type Props = {
  transparent?: boolean
}

function NutritionHeader({ transparent = false }: Props) {
  const { pathname } = useLocation()
  useEffect(() => {
    let exited = false
    ;(async () => {
      if (!window.location.search.match(/preview/)) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
      }
      if (exited) {
        return
      }
      import('@adjustcom/smart-banner-sdk').then(
        ({ default: AdjustSmartBanner }) => {
          AdjustSmartBanner.init({
            bannerParent: document.querySelector(
              '#adjust-smart-banner-mount',
            ) as HTMLElement,
            appToken: {
              ios: adjustIosSmartBannerAppToken as string,
              android: adjustAndroidSmartBannerAppToken as string,
            },
          })
        },
      )
    })()

    return () => {
      exited = true
    }
  }, [])

  const links = {
    home: {
      name: 'Home',
      link: '/nutrition-explained/',
      selected: pathname.match(/\/nutrition-explained\/?$/),
    },
    stories: {
      name: 'Stories',
      link: '/nutrition-explained/page/1/',
      selected: pathname.match(/(\/page\/)+/),
    },
    recipes: {
      name: 'Recipes',
      link: '/nutrition-explained/recipes/',
      selected: pathname.startsWith('/nutrition-explained/recipe'),
    },
  }

  const hamburgerMenyLinks = [
    {
      text: 'Home',
      href: '/nutrition-explained/',
    },
    {
      text: 'Stories',
      href: '/nutrition-explained/page/1/',
    },
    {
      text: 'Recipes',
      href: '/nutrition-explained/recipes/',
    },
  ]

  return (
    <header
      className={cn(
        'relative z-10',
        !transparent && 'border-b-1 border-Border bg-Bg',
      )}
      data-datocms-noindex
    >
      <div className="md:o-width-limiter relative px-16">
        <div className="flex items-center justify-between py-8 lg:py-16">
          <div className="flex flex-row items-center">
            <HamburgerMenu
              links={hamburgerMenyLinks}
              inverted={transparent}
              showOnlyOnMobile
            />
            <Logo inverted={transparent} />
          </div>
          <nav>
            <ul className="hidden justify-center md:flex">
              {Object.values(links).map(({ link, name, selected }, i) => {
                return (
                  <li key={`link-${i}`}>
                    <Link
                      to={link}
                      className={cn(
                        'block rounded-full',
                        'leading-small type-14-demibold sm:type-14-demibold-caps lg:type-17-demibold-caps',
                        'pt-4 sm:pt-6',
                        selected
                          ? 'mx-4 px-12 md:mx-8 md:px-16'
                          : 'mx-8 md:mx-16',
                        selected && transparent && 'bg-white text-Type',
                        selected &&
                          !transparent &&
                          'bg-green text-TypeConstant',
                        !selected && transparent && 'text-TypeConstant',
                        !selected && !transparent && 'text-Type',
                      )}
                    >
                      <span
                        className={cn(
                          'inline-block border-b-2 border-transparent pb-2 transition',
                          !selected &&
                            transparent &&
                            'hover:border-TypeConstant',
                          !selected && !transparent && 'hover:border-green',
                        )}
                      >
                        {name}
                      </span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
          <SearchField />
        </div>
      </div>
    </header>
  )
}

export default NutritionHeader
