import React, { ReactNode } from 'react'

interface Props {
  text: ReactNode
}

const LinkableText = ({ text }: Props) => (
  <div className="text-center text-Type hover:text-TypeSub focus:text-TypeSub">
    <span>{text}</span>
  </div>
)

export default LinkableText
