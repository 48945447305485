import React from 'react'
import Heat from '../assets/heat.svg?svgr-webpack'
import Pomodoro from '../assets/pomodoro.svg?svgr-webpack'

type Props = {
  small?: boolean
  time: number
  calories: number
}

export default function Meta({ small, time, calories }: Props) {
  const formattedTime = () => {
    const hours = Math.floor(time / 60)
    const mins = time % 60

    if (hours && !mins) return `${hours} h`
    if (hours && mins) return `${hours} h ${mins} min`
    return `${mins} min`
  }

  if (small)
    return (
      <ul className="type-upper mt-12 flex justify-center">
        {time && (
          <li className="mr-20 flex">
            <Pomodoro className="w-16 text-green" />
            <span className="ml-8 mt-4">{formattedTime()}</span>
          </li>
        )}
        {calories && (
          <li className="flex">
            <Heat className="w-16 text-green" />
            <span className="ml-8 mt-4">{calories} kcal</span>
          </li>
        )}
      </ul>
    )

  return (
    <ul className="type-upper flex">
      {time && (
        <li className="mr-24 flex">
          <span className="flex h-48 w-48 rounded-full border-1 border-Border">
            <Pomodoro className="m-auto text-green" />
          </span>
          <span className="my-auto ml-12">{formattedTime()}</span>
        </li>
      )}
      {calories && (
        <li className="flex">
          <span className="flex h-48 w-48 rounded-full border-1 border-Border">
            <Heat className="m-auto text-green" />
          </span>
          <span className="my-auto ml-12">{calories} kcal</span>
        </li>
      )}
    </ul>
  )
}
