import React, {
  useRef,
  useCallback,
  createContext,
  forwardRef,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'

const topClass = 'c-slideshow__item--on-top'
const leftClass = 'c-slideshow__item--left'
const rightClass = 'c-slideshow__item--right'
const transitionToFrontClass = 'c-slideshow__item--to-top'
const transitionToBackLeftClass = 'c-slideshow__item--to-back-left'
const transitionToBackRightClass = 'c-slideshow__item--to-back-right'
const transitionFromLeftToRightClass = 'c-slideshow__item--left-to-right'
const transitionFromRightToLeftClass = 'c-slideshow__item--right-to-left'

const SlideshowContext = createContext()

const Slideshow = forwardRef(
  ({ children, rotateForwards, rotateBackwards }, ref) => {
    const wrapper = ref
    const handlers = useSwipeable({
      onSwipedLeft() {
        rotateForwards()
      },
      onSwipedRight() {
        rotateBackwards()
      },
      preventDefaultTouchmoveEvent: true,
      trackMouse: true,
    })

    const mergeRefs = useCallback(
      (node) => {
        wrapper.current = node
        handlers.ref(node)
      },
      [handlers, wrapper],
    )

    const { ref: _swipeRef, ...swipeHandlers } = handlers

    return (
      <div ref={mergeRefs} {...swipeHandlers}>
        {children}
      </div>
    )
  },
)

Slideshow.displayName = 'Slideshow'

Slideshow.propTypes = {
  children: PropTypes.node.isRequired,
  onInteraction: PropTypes.func,
  rotateForwards: PropTypes.func,
  rotateBackwards: PropTypes.func,
}

export default function SlideshowProvider({
  onInteraction = () => {},
  children,
  ...props
}) {
  const wrapper = useRef()

  function rotate(e) {
    const target = e.currentTarget

    // Do nothing if the top one is clicked
    if (target.classList.contains(topClass)) return

    const forwards = target.classList.contains(rightClass)
    const left = wrapper.current.querySelector(`.${leftClass}`)
    const right = wrapper.current.querySelector(`.${rightClass}`)
    const top = wrapper.current.querySelector(`.${topClass}`)

    // Add transition classes
    target.classList.add(transitionToFrontClass)

    if (forwards) {
      top.classList.add(transitionToBackLeftClass)
      left.classList.add(transitionFromLeftToRightClass)
    } else {
      right.classList.add(transitionFromRightToLeftClass)
      top.classList.add(transitionToBackRightClass)
    }

    // Replace transition classes and old regular classes with new, regular ones
    setTimeout(() => {
      const backRight = wrapper.current.querySelector(
        `.${transitionToBackRightClass}`,
      )
      const backLeft = wrapper.current.querySelector(
        `.${transitionToBackLeftClass}`,
      )
      const leftRight = wrapper.current.querySelector(
        `.${transitionFromLeftToRightClass}`,
      )
      const rightLeft = wrapper.current.querySelector(
        `.${transitionFromRightToLeftClass}`,
      )

      target.classList.remove(transitionToFrontClass, leftClass, rightClass)
      target.classList.add(topClass)

      if (backRight) {
        backRight.classList.add(rightClass)
        backRight.classList.remove(transitionToBackRightClass, topClass)
      }

      if (backLeft) {
        backLeft.classList.add(leftClass)
        backLeft.classList.remove(transitionToBackLeftClass, topClass)
      }

      if (leftRight) {
        leftRight.classList.add(rightClass)
        leftRight.classList.remove(transitionFromLeftToRightClass, leftClass)
      }

      if (rightLeft) {
        rightLeft.classList.add(leftClass)
        rightLeft.classList.remove(transitionFromRightToLeftClass, rightClass)
      }
    }, 750)

    onInteraction()
  }

  function rotateForwards() {
    wrapper.current.querySelector(`.${rightClass}`).click()
  }

  function rotateBackwards() {
    wrapper.current.querySelector(`.${leftClass}`).click()
  }

  const slidesIndex =
    Array.isArray(children) &&
    children.findIndex((child) => {
      return child.type === Slides
    })

  return (
    <SlideshowContext.Provider
      value={{ rotateForwards, rotateBackwards, rotate }}
    >
      {slidesIndex ? children.slice(0, slidesIndex) : null}
      <Slideshow
        {...props}
        ref={wrapper}
        rotateForwards={rotateForwards}
        rotateBackwards={rotateBackwards}
        rotate={rotate}
      >
        {slidesIndex ? children[slidesIndex] : children}
      </Slideshow>
      {slidesIndex ? children.slice(slidesIndex + 1) : null}
    </SlideshowContext.Provider>
  )
}

SlideshowProvider.propTypes = {
  onInteraction: PropTypes.func,
  children: PropTypes.node,
}

export function useSlideshow() {
  const context = useContext(SlideshowContext)

  if (context === undefined) {
    throw new Error(`useCounter must be used within a CounterProvider`)
  }

  return context
}

export function SlideshowNav() {
  const { rotateForwards, rotateBackwards } = useContext(SlideshowContext)
  return (
    <div className="c-slideshow__nav mt-20 flex justify-center">
      <h3 className="sr-only">Navigate testimonials</h3>
      <button onClick={rotateBackwards} className="c-slideshow__nav-button">
        <span>Previous</span>
      </button>
      <button
        onClick={rotateForwards}
        className="c-slideshow__nav-button c-slideshow__nav-button--fwd"
      >
        <span>Next</span>
      </button>
    </div>
  )
}

export function Slides({ children }) {
  const { rotate } = useContext(SlideshowContext)

  if (children.length !== 3) {
    throw new Error('Slideshow requires exactly 3 slides')
  }

  return (
    <ul className="relative mx-auto grid grid-rows-1 px-32 lg:px-64">
      {React.cloneElement(children[0], {
        onClick: rotate,
        className: topClass,
      })}
      {React.cloneElement(children[1], {
        onClick: rotate,
        className: rightClass,
      })}
      {React.cloneElement(children[2], {
        onClick: rotate,
        className: leftClass,
      })}
    </ul>
  )
}

Slides.propTypes = {
  children: PropTypes.node,
}
