import React from 'react'
import cn from 'classnames'
import DatoImage from '../../../components/DatoRichText/DatoImage'
import { Link } from 'react-router-dom'
import Arrow from '../assets/arrow-right.svg?svgr-webpack'
import { TeaserFragment as TeaserFragmentProps } from './types/TeaserFragment'
import { captureException } from 'js/utils/captureException'
import LinkableText from './LinkableText'

type ImageRelatedTeaserFragmentProps = Pick<
  TeaserFragmentProps,
  'rectangularImage' | 'squareImage' | 'image'
>

type ExcerptProps = {
  content: string
}

function Excerpt({ content }: ExcerptProps) {
  const wordsArray = content.split(' ')
  const last = wordsArray.pop()

  return (
    <>
      {wordsArray.join(' ')}
      <span className="whitespace-nowrap" key="last">
        {` ${last}`}
        <span
          className={`
              ml-6 inline-flex
              h-24 w-24
              origin-left
              transition 
            `}
        >
          <Arrow className="m-auto" />
        </span>
      </span>
    </>
  )
}

type ImageProps = {
  big: boolean
  className: string
  slug: string // just used for creating errors if image is missing
} & ImageRelatedTeaserFragmentProps
const Image = ({
  image,
  big,
  rectangularImage,
  squareImage,
  className,
  slug,
}: ImageProps) => {
  const selectedImage = big ? rectangularImage : squareImage
  const imageUrlParams = new URLSearchParams(selectedImage?.url)
  if (!image || !rectangularImage || !squareImage) {
    captureException(
      `missing image on nutrition article with slug: ${slug} on (${
        window && window.location?.href
      })`,
    )
    return null
  }

  return (
    <DatoImage
      width={imageUrlParams.get('w') || undefined}
      height={imageUrlParams.get('h') || undefined}
      alt={image.alt}
      title={image.title || undefined}
      src={selectedImage?.url as string}
      lazy
      base64={image.blurUpThumb}
      className={className}
    />
  )
}

type Props = {
  big?: boolean
  slug: string
  title?: string
  excerpt: string
} & ImageRelatedTeaserFragmentProps

const Teaser = ({ big = false, excerpt, slug, title, ...rest }: Props) => {
  return (
    <div className={cn('group relative flex text-left', big && 'flex-col')}>
      <div className={cn(!big && 'w-1/4 shrink-0 lg:w-1/3 xxl:w-1/4')}>
        <Image
          slug={slug}
          big={big}
          {...rest}
          className="w-full rounded-8 lg:rounded-12 xl:rounded-16"
        />
      </div>
      <div
        className={
          big
            ? undefined
            : `
          my-auto ml-16 md:ml-24 md:w-3/4
          lg:w-2/3 xl:ml-32 xxl:w-3/4
        `
        }
      >
        <Link to={`/nutrition-explained/${slug}`}>
          <LinkableText
            text={
              <>
                <h3
                  className={cn(
                    'type-med-minus-fat mb-6 lg:mb-12',
                    big && 'mt-20',
                  )}
                >
                  {title}
                </h3>
                <p>
                  <Excerpt content={excerpt as string} />
                </p>
              </>
            }
          />
        </Link>
      </div>
    </div>
  )
}

export const TeaserFragment = gql`
  fragment TeaserFragment on NutritionArticleRecord {
    id
    slug
    title
    excerpt
    image {
      alt
      title
      blurUpThumb
    }
    squareImage: image {
      url(imgixParams: { w: 320, h: 320, fm: jpg, fit: crop, crop: focalpoint })
    }
    rectangularImage: image {
      url(imgixParams: { w: 480, h: 320, fm: jpg, fit: crop, crop: focalpoint })
    }
  }
`

export default Teaser
