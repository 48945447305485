import React, { useState } from 'react'
import convert from 'htmr'
import cn from 'classnames'
import DatoImage from '../../../components/DatoRichText/DatoImage'
import { Link } from 'react-router-dom'
import RecipeMeta from './RecipeMeta'
import {
  AllStoriesCategory_allRecipes,
  AllStoriesCategory_allRecipes_image_responsiveImage,
} from '../queries/types/AllStoriesCategory'
import { NonNullableObject } from 'typings'
import LinkableText from './LinkableText'

type HeaderProps = {
  children: React.ReactNode
}

const Header = ({ children }: HeaderProps) => (
  <h2 className="type-default-fat leading-body">
    <span>{children}</span>
  </h2>
)

type ImageProps = {
  className?: string
  sizes?: string
  image: {
    responsiveImage: AllStoriesCategory_allRecipes_image_responsiveImage
  }
}

const Image = ({ image, className, sizes }: ImageProps) => (
  <DatoImage
    sizes={sizes}
    {...image.responsiveImage}
    title={image?.responsiveImage?.title || undefined}
    lazy
    className={className}
  />
)

type Props = {
  sizes: string
} & NonNullableObject<AllStoriesCategory_allRecipes>

const RecipeTeaser = ({
  intro,
  recipeName,
  slug,
  time,
  calories,
  image,
  ...rest
}: Props) => {
  const [isOpen, setOpen] = useState(false)

  const introText = !isOpen ? intro.substring(0, 140) + '…' : intro
  return (
    <div
      className={cn(
        'flex flex-1 flex-col',
        'group relative text-left',
        'bg-Bg shadow-subtle',
        'border-1 border-Border',
        'rounded-8 lg:rounded-12 xl:rounded-16',
      )}
    >
      <Image
        image={
          image as {
            responsiveImage: AllStoriesCategory_allRecipes_image_responsiveImage
          }
        }
        {...rest}
        className="w-full rounded-t-8 lg:rounded-t-12 xl:rounded-t-16"
      />
      <div
        className="flex grow flex-col px-20 py-16"
        style={{ minHeight: '125px' }}
      >
        {slug ? (
          <Link to={`/nutrition-explained/recipe/${slug}`}>
            <LinkableText
              text={
                <>
                  <Header>{convert(recipeName)}</Header>
                  {intro && (
                    <p className="mt-6 leading-body">
                      {intro.length > 140
                        ? intro.substring(0, 140) + '…'
                        : intro}
                    </p>
                  )}
                </>
              }
            />
          </Link>
        ) : (
          <div
            onClick={() => setOpen(!isOpen)}
            className="cursor-pointer hover:text-TypeSub"
          >
            <Header>{convert(recipeName)}</Header>
            {intro && <p dangerouslySetInnerHTML={{ __html: introText }} />}
          </div>
        )}

        <div className="mr-auto mt-auto">
          <RecipeMeta small time={time} calories={calories} />
        </div>
      </div>
    </div>
  )
}

export default RecipeTeaser
