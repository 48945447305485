import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { TeaserFragment } from './types/TeaserFragment'

type Props = {
  className?: string
} & TeaserFragment

export default function StoryCard({
  title,
  image,
  rectangularImage,
  slug,
}: Props) {
  const fallbackBackgrounds = [
    'bg-green-lightest',
    'bg-blue-light opacity-25',
    'bg-magenta-light opacity-50',
    'bg-BgSub',
  ]

  return (
    <Link
      to={`/nutrition-explained/${slug}`}
      className={cn(
        'ml-20 block flex-1 md:ml-0',
        'shadow-subtle',
        'rounded-8 bg-BgContent transition lg:rounded-12',
      )}
    >
      <div className="relative overflow-hidden rounded-t-8 lg:rounded-t-12">
        <div
          className={`absolute inset-0 ${
            fallbackBackgrounds[
              Math.floor(Math.random() * fallbackBackgrounds.length)
            ]
          }`}
        />
        {image && (
          <img
            src={rectangularImage?.url}
            alt={image.alt || undefined}
            width="240"
            height="160"
            className="relative w-full"
          />
        )}
      </div>
      <p className="type-small mx-12 my-8 leading-body text-Type lg:mx-16 lg:my-12">
        {title}
      </p>
    </Link>
  )
}
