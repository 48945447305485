import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import useLocale from 'js/hooks/useLocale'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

function withSSR(WrappedComponent) {
  function WrappedPage({ initialData, initialPathname, ...rest }) {
    const location = useLocation()
    const params = useParams()
    const [searchParams, _] = useSearchParams()
    const [data, setData] = useState(
      initialPathname === location.pathname ? initialData : null,
    )
    const [isLoading, setIsLoading] = useState(!data)
    const [error, setError] = useState(null)
    const { language } = useLocale()

    const fetchData = useCallback(() => {
      // reload the data without displaying loader if the data has been
      // preloaded on the server side
      if (location.pathname !== initialPathname && !data) {
        setIsLoading(true)
      }

      if (window?.performance?.mark) {
        window.performance.mark('withSSR-refetch-start')
      }

      WrappedComponent.getInitialProps({
        location,
        params,
        isPreview: searchParams.has('preview'),
        language,
      })
        .then((data) => {
          if (
            typeof data === 'undefined' ||
            data === null ||
            data.length === 0 ||
            Object.entries(data).length === 0
          ) {
            setError('Not found')
            setIsLoading(false)
            return
          }
          setData(data)
          setIsLoading(false)
          if (window?.performance?.mark) {
            window.performance.mark('withSSR-refetch-end')
            window.performance.measure(
              'withSSR-refetch',
              'withSSR-refetch-start',
              'withSSR-refetch-end',
            )
          }
        })
        .catch((error) => {
          setError(error)
          setIsLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, params, location, searchParams])

    useEffect(() => {
      fetchData()
    }, [location, fetchData])

    return (
      <WrappedComponent
        params={params}
        location={location}
        {...rest}
        error={error}
        refetch={fetchData}
        apiCall={WrappedComponent.getInitialProps}
        data={data}
        isPreview={searchParams.has('preview')}
        isLoading={isLoading}
      />
    )
  }

  WrappedPage.propTypes = {
    initialData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    initialPathname: PropTypes.string,
    params: PropTypes.object,
    location: PropTypes.object,
  }

  WrappedPage.displayName = `WithSSR(${WrappedComponent.displayName})`
  WrappedPage.getInitialProps = WrappedComponent.getInitialProps

  return WrappedPage
}

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  params: PropTypes.object,
  refetch: PropTypes.func,
}

export { propTypes }
export default withSSR
