import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import Spinner from '../../../components/Spinner/Spinner'
import { TeaserFragment } from './Teaser'
import { runQuery } from '../../../dato'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import ArrowRight from 'media/arrow-right.svg?svgr-webpack'
import StoryCard from './NutritionStartCategoryStoryCard'
import { TeaserFragment as TeaserFragmentType } from './types/TeaserFragment'
import LSButton from 'lifesum-web/src/js/components/LSButton'

type Props = {
  categories: Array<{ id: string; name: string; slug: string }>
}

export default function NutritionStartCategories({ categories }: Props) {
  return (
    <>
      {categories.map((category) => {
        return <Category key={category.id} {...category} />
      })}
      <LSButton
        variant="primary"
        link={true}
        upperCase
        href="/nutrition-explained/page/1/"
        className="mt-12"
      >
        Browse more stories
      </LSButton>
    </>
  )
}

const query = gql`
  query CategoryDataStart($id: ItemId!) {
    categoryArticles: allNutritionArticles(
      first: 5
      filter: { categories: { anyIn: [$id] } }
      orderBy: _createdAt_DESC
    ) {
      ...TeaserFragment
    }
  }
  ${TeaserFragment}
`

type CategoryProps = {
  id: string
  name: string
  slug: string
}

function Category({ id, name, slug }: CategoryProps) {
  const [inViewRef, inView] = useInView({ triggerOnce: true })
  const [articles, setArticles] = useState<TeaserFragmentType[]>([])

  useEffect(() => {
    if (id && inView)
      runQuery(query, { id }).then(({ categoryArticles }) =>
        setArticles(categoryArticles),
      )
  }, [id, inView])

  return (
    <div className="mb-32 text-left" ref={inViewRef}>
      <h3
        className={cn(
          'mb-12 xl:mb-24',
          'type-upper md:type-20-bold xl:type-28-bold',
          'md:normal-case md:tracking-normal',
        )}
      >
        {name}
      </h3>
      {!articles.length ? (
        <div className="my-48 text-center">
          <Spinner color="green" width="50" />
        </div>
      ) : (
        <ul
          className={cn(
            '-mx-20 pb-12 md:mx-0 md:pb-0 xl:pb-32',
            'flex items-stretch',
            'grid-cols-3 md:grid md:gap-6 lg:grid-cols-5 lg:gap-5 xl:gap-6',
            'overflow-x-scroll md:overflow-x-visible',
          )}
          style={{
            scrollSnapType: 'x mandatory',
          }}
        >
          {articles.map((article) => {
            return (
              <li
                key={article.id}
                className="flex md:min-w-0 lg:first:hidden"
                style={{ minWidth: '43vw', scrollSnapAlign: 'start' }}
              >
                <StoryCard {...article} />
              </li>
            )
          })}
          <li
            className="flex md:min-w-0"
            style={{ minWidth: 'calc(43vw + 20px)' }}
          >
            <Link
              to={`/nutrition-explained/category/${slug}`}
              className={cn(
                'group flex flex-1',
                'ml-20 mr-20 md:ml-0 md:mr-0',
                'lg:rounded-12',
                'type-upper-fat text-center',
              )}
            >
              <div className="m-auto text-center text-Type hover:text-TypeSub">
                <div>
                  <ArrowRight className="m-auto" />
                </div>
                <div>
                  See more
                  <span className="sr-only"> {name} articles</span>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      )}
    </div>
  )
}
