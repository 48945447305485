import React from 'react'
import cn from 'classnames'
import ArrowRight from 'media/arrow-right.svg?svgr-webpack'
import { Link } from 'react-router-dom'

type GoalLinkProps = {
  name: string
  description: string
  image: {
    url: string
  }
  slug: string
}

function GoalLink({ name, description, slug, image }: GoalLinkProps) {
  return (
    <Link to={`/nutrition-explained/category/${slug}`}>
      <section
        className={cn(
          'group relative bg-BgContent lg:rounded-16',
          'flex flex-col items-center justify-between',
          'px-8 py-12 lg:px-12 lg:py-24',
          'type-14 md:type-17 lg:type-20',
          'h-full text-Type hover:text-TypeSub',
        )}
      >
        <div>
          <img
            src={image.url}
            alt=""
            style={{ height: 'min(20vw, 160px)' }}
            className={cn(
              'mb-16',
              name === 'Gain weight' && 'origin-bottom scale-125 transform',
            )}
          />
          <h1 className="mb-4 font-demibold">{name}</h1>
          <p className="hidden leading-body md:block">{description}</p>
        </div>

        <ArrowRight className="mx-auto mt-16 flex h-32 w-32 lg:h-40 lg:w-40" />
      </section>
    </Link>
  )
}

type Props = {
  goals: any[]
}
export default function NutritionStartGoal({ goals }: Props) {
  return (
    <div className="-mx-20 grid grid-cols-3 gap-0 lg:mx-0 lg:gap-4 xl:gap-6">
      {goals.map((goal) => (
        <GoalLink key={goal.id} {...goal} />
      ))}
    </div>
  )
}
