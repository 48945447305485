import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { LatestNutritionArticles_start_diets } from '../types/LatestNutritionArticles'
import LSButton from 'lifesum-web/src/js/components/LSButton'

type DietCardProps = {
  index: number
  className?: string
} & LatestNutritionArticles_start_diets
function DietCard({ name, image, slug, index, className }: DietCardProps) {
  return (
    <div
      className={cn(
        'group relative m-5 h-[200px] overflow-clip rounded-8 bg-LSGreenDarkest text-left',
        'col-span-3 lg:col-span-2',
        index === 0 ? 'text-20' : 'text-14',
        'md:text-24 lg:text-28',
        className,
      )}
    >
      <Link to={`/nutrition-explained/category/${slug}`} className="fill-link">
        {image && (
          <img
            src={image.url}
            alt=""
            className="h-full w-full translate-x-2/4 object-contain"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <span className="quincy absolute bottom-0 left-0 mb-12 ml-16 font-bold text-white type-28 lg:mb-20 lg:ml-30">
          {name}
        </span>
      </Link>
    </div>
  )
}

type Props = {
  diets: LatestNutritionArticles_start_diets[]
}

function NutritionStartDiets({ diets }: Props) {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div
        className={cn(
          'grid grid-cols-6 gap-1 lg:gap-6',
          '-mx-20 mb-24 lg:mx-0 lg:mb-48',
        )}
      >
        {diets.map((diet, index) => (
          <DietCard
            key={diet.id}
            index={index}
            {...diet}
            className={!expanded && index > 2 ? 'hidden' : undefined}
          />
        ))}
      </div>
      {!expanded && diets.length > 2 && (
        <LSButton
          link={false}
          variant="secondary"
          size="small"
          className="mx-auto"
          onClick={() => setExpanded(true)}
        >
          See all diets
        </LSButton>
      )}
    </>
  )
}

export default NutritionStartDiets
