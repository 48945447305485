import React from 'react'
import cn from 'classnames'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from 'react-router-dom'
import Logo from 'js/components/Logo'

type NavLinkProps = {
  children: React.ReactNode
  to: string
}

const NavLink = ({ children, to, ...rest }: NavLinkProps) => (
  <Link
    className={cn(
      'type-upper mx-12 inline-block text-Type transition',
      'border-b-1 border-Border hover:border-Type',
    )}
    to={to}
    {...rest}
  >
    {children}
  </Link>
)

const NutritionFooter = () => (
  <footer className="bg-BgContent text-center">
    <div className="py-40 lg:py-64 xl:py-80">
      <Logo className="mb-24" />
      <div className="s-text mx-auto max-w-768">
        <p>
          Lifesum is one of the world’s leading nutrition specialists. We’re on
          a mission to make it easier for everyone to live a healthy life and
          make an impact on global wellness.
        </p>
        <p className="font-demibold">Healthy eating. Simplified.</p>
      </div>
      <ul className="mb-24 flex-row md:mb-40">
        <li>
          <a
            className="mb-24 text-Type hover:text-TypeSub"
            href="https://instagram.com/lifesum/"
          >
            Instagram
          </a>
        </li>
        <li>
          <a
            className="mb-24 text-Type hover:text-TypeSub"
            href="https://www.youtube.com/channel/UCy64gY3TCURHxigExH0cHIg"
          >
            YouTube
          </a>
        </li>
        <li>
          <a
            className="mb-24 text-Type hover:text-TypeSub"
            href="https://www.facebook.com/Lifesum"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            className="mb-24 text-Type hover:text-TypeSub"
            href="https://twitter.com/lifesum"
          >
            Twitter
          </a>
        </li>
      </ul>
      <nav className="pt-20">
        <ul className="flex justify-center">
          <li>
            <NavLink to="/nutrition-explained/experts/">Advisory board</NavLink>
          </li>
          <li>
            <NavLink to="/nutrition-explained/archive/1/">Archive</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
)

export default NutritionFooter
