import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export default function Slide({ children, onClick, className }) {
  return (
    <li
      className={cn(
        'relative col-span-1 col-start-2  row-span-1 row-start-1 flex overflow-hidden rounded-8 shadow-subtle md:rounded-16',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </li>
  )
}

Slide.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
