import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import StopWatch from '../assets/stopwatch.svg?svgr-webpack'
import {
  LatestNutritionArticles_latestArticles,
  LatestNutritionArticles_start_featuredStory,
} from '../types/LatestNutritionArticles'
import { NonNullableObject } from 'typings'
import LSButton from 'js/components/LSButton'
import LinkableText from './LinkableText'
type FeaturedStoryProps = {
  image: {
    url: string
  }
  desktopImage: { url: string }
  title: string
  excerpt: string
  className?: string
  slug: string
  content: any[]
}

function FeaturedStory({
  image,
  desktopImage,
  title,
  excerpt,
  className,
  slug,
  content,
}: FeaturedStoryProps) {
  const words = content
    .map(({ text }) => text)
    .join()
    .split(' ')
  const minutes = words.length / 200
  const readingTime = Math.ceil(minutes)

  return (
    <Link to={`/nutrition-explained/${slug}`} className="group">
      <section
        className={cn(
          'group relative grid border-none lg:grid-cols-2 lg:rounded-16 lg:border lg:shadow-subtle',
          '-mx-20 overflow-hidden text-Type lg:mx-0',
          className,
        )}
      >
        <div className="type-upper-fat absolute z-10 mt-24 rounded-r-full px-20 pb-4 pt-6 uppercase text-white lg:mt-32">
          Featured story
        </div>
        <div>
          {image && (
            <img
              srcSet={`${image.url} 375w, ${desktopImage.url} 640w`}
              sizes="(max-width: 999px) 375px, 640px"
              src={image.url}
              alt=""
              className="mb-16 h-full w-full lg:mb-0 lg:rounded-l-16"
            />
          )}
        </div>
        <div className="Bg-BgContent flex h-full flex-col items-center justify-center bg-BgContent px-20 pb-12 text-center lg:px-40 lg:py-24">
          <h1 className="quincy py-28 text-32">{title}</h1>
          <div className="mb-12 flex text-grey type-14-caps">
            <span className="mx-8 flex items-center">
              <StopWatch className="mr-6" />
              {`${readingTime} min. read`}
            </span>
          </div>
          <div className="mb-36 leading-body">{excerpt}</div>
          <LSButton
            link={true}
            variant="secondary"
            size="small"
            className="mx-auto lg:order-3"
            href={`/nutrition-explained/${slug}`}
          >
            Read more<span className="sr-only">about {title}</span>
          </LSButton>
        </div>
      </section>
    </Link>
  )
}

type ExcerptProps = {
  content: string
}

function Excerpt({ content }: ExcerptProps) {
  const wordsArray = content.split(' ')
  const last = wordsArray.pop()

  return (
    <LinkableText
      text={
        <>
          {wordsArray.join(' ')}
          <span key="last">
            {` ${last}`}

            <span key="last"></span>
          </span>
        </>
      }
    />
  )
}

type StoryCardProps = {
  image: { url: string } | null
  title: string
  className?: string
  slug: string
}

function StoryCard({ title, image, slug }: StoryCardProps) {
  return (
    <div className="last:hidden lg:last:block">
      <Link to={`/nutrition-explained/${slug}`} className="group">
        <div className="flex grow items-center lg:flex-col lg:items-start">
          <div
            className={cn(
              'w-2/5 shrink-0 lg:w-full',
              'rounded-6 lg:rounded-8',
              'mb-auto overflow-hidden',
            )}
          >
            {image && <img src={image.url} alt="" width="240" height="160" />}
          </div>
          <p className="ml-16 text-left text-black lg:ml-0 lg:mt-8">
            <span
              className="leading-body"
              style={{ width: 'calc(100% - 20px)' }}
            >
              <Excerpt content={title} />
            </span>
          </p>
        </div>
      </Link>
    </div>
  )
}

type Props = {
  featuredStory: NonNullableObject<LatestNutritionArticles_start_featuredStory> | null
  latestArticles: NonNullableObject<LatestNutritionArticles_latestArticles>[]
}

export default function NutritionStartStories({
  featuredStory,
  latestArticles,
}: Props) {
  return (
    <div className="grid-col grid">
      <div className="mb-32 grid w-full gap-6 lg:order-2 lg:grid-cols-4">
        {latestArticles.map((story) => (
          <StoryCard key={story.id} {...story}></StoryCard>
        ))}
      </div>
      <LSButton
        link={true}
        variant="secondary"
        size="small"
        className="mx-auto lg:order-3"
        href="/nutrition-explained/page/1/"
      >
        See more
      </LSButton>
      {featuredStory && (
        <FeaturedStory
          {...featuredStory}
          className="mt-48 lg:order-1 lg:mb-56 lg:mt-0"
        />
      )}
    </div>
  )
}
