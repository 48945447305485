import React, { HTMLAttributes, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'

type Props = {
  src: string
  width?: number | string
  height?: number | string
  className?: string
  srcSet?: string
  webpSrcSet?: string
  base64?: string | null
  dato?: boolean
  alt?: string | null
  sizes?: string
  lazy?: boolean
  style?: React.CSSProperties
  aspectRatio?: number
} & HTMLAttributes<HTMLImageElement>

export default function DatoImage({
  src,
  srcSet,
  webpSrcSet,
  alt = '',
  sizes = '(min-width: 1280px) 1280px, 100vw',
  className,
  width,
  height,
  lazy,
  base64,
  style,
  ...props
}: Props) {
  const [inViewRef, inView] = useInView({ triggerOnce: true })
  const [loaded, setLoaded] = useState(lazy ? false : true)

  function handleLoad() {
    setLoaded(true)
  }

  const source =
    base64 ||
    `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E`

  const { aspectRatio: _aspectRatio, ...safeProps } = props

  return (
    <div className="relative">
      {(!lazy || inView) && (
        <picture className={cn(lazy && !loaded && 'block h-0')}>
          {webpSrcSet && (
            <source srcSet={webpSrcSet} sizes={sizes} type="image/webp" />
          )}
          {srcSet && <source srcSet={srcSet} sizes={sizes} />}
          <img
            src={src}
            alt={alt ? alt : undefined}
            height={height}
            width={width}
            onLoad={handleLoad}
            {...safeProps}
            className={className}
            style={style}
          />
        </picture>
      )}
      <div
        className={cn(
          'transition-slower transition-opacity',
          !loaded && 'relative h-0 overflow-hidden',
          loaded &&
            'pointer-events-none absolute left-0 top-0 h-full w-full opacity-0',
        )}
        style={
          !loaded
            ? { paddingTop: `${(Number(height) / Number(width)) * 100}%` }
            : {}
        }
        ref={inViewRef}
      >
        <img
          src={source}
          height={height}
          width={width}
          className={cn('absolute inset-0', className)}
          style={{
            backgroundImage: `url(${source})`,
            backgroundPosition: 'top -30px right -30px',
            backgroundSize: `${Number(width) + 60}px ${Number(height) + 60}px`,
          }}
        />
      </div>
    </div>
  )
}
