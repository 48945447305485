import React from 'react'
import cn from 'classnames'
import NutritionHeader from './components/NutritionHeader'
import { runQuery } from 'js/dato'
import withSSR from 'js/components/withSSR/withSSR'
import NutritionStartDiets from './components/NutritionStartDiets'
import NutritionStartRecipes from './components/NutritionStartRecipes'
import NutritionStartStories from './components/NutritionStartStories'
import NutritionStartGoals from './components/NutritionStartGoals'
import NutritionStartCategories from './components/NutritionStartCategories'
import SymbolHeading from './components/SymbolHeading'
import FoodCover from './assets/food-cover.svg?svgr-webpack'
import SaucePanHot from './assets/sauce-pan-hot.svg?svgr-webpack'
import BusinessTarget from './assets/business-target.svg?svgr-webpack'
import Tag from './assets/price-tag.svg?svgr-webpack'
import NutritionFooter from './components/NutritionFooter'
import { Title } from 'react-head'
import {
  LatestNutritionArticles_latestArticles,
  LatestNutritionArticles_nutritionTime,
  LatestNutritionArticles_start,
  LatestNutritionArticles_start_categories,
  LatestNutritionArticles_start_featuredRecipes,
  LatestNutritionArticles_start_featuredStory,
} from './types/LatestNutritionArticles'
import { NonNullableObject, RecursiveNonNullableObject } from 'typings'

type SectionProps = {
  children: React.ReactNode
  className?: string
  width?: 'narrow' | 'medium' | 'wide'
}

function Section({ children, className, width }: SectionProps) {
  return (
    <div className={cn('bg-BgMain pb-48 pt-40 lg:pb-72', className)}>
      <div
        className={cn(
          'mx-auto px-20 text-center',
          width === 'narrow' && 'max-w-968',
          (width === 'medium' || !width) && 'max-w-1280',
          width === 'wide' && 'max-w-1680',
        )}
      >
        {children}
      </div>
    </div>
  )
}

type Props = {
  data: {
    latestArticles: NonNullableObject<LatestNutritionArticles_latestArticles>[]
    start: LatestNutritionArticles_start
    nutritionTime: RecursiveNonNullableObject<LatestNutritionArticles_nutritionTime>
  }
  isLoading: boolean
}

function NutritionStart({ data, isLoading }: Props) {
  if (isLoading) return null
  const { start, latestArticles } = data
  const { featuredStory, goals, diets, featuredRecipes, categories } = start

  return (
    <>
      <Title>Nutrition Explained - Lifesum</Title>
      <NutritionHeader />
      <div
        className="bg-BgSub px-16 pb-80 pt-48 text-center lg:pb-128 lg:pt-72"
        style={{
          backgroundImage:
            'url(https://www.datocms-assets.com/23496/1667211510-lifesum_gradient_green.png?&w=375&h=400&auto=format)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <h1 className="type-large-fat text-white">Nutrition Explained</h1>
        <div className="mx-auto mt-12 max-w-576 leading-body type-17 md:mt-16 md:type-24 lg:type-28">
          Learn about nutrition from Lifesum’s in-house nutritionists and
          invited experts.
        </div>
      </div>
      <Section width="narrow">
        <NutritionStartStories
          featuredStory={
            featuredStory as NonNullableObject<LatestNutritionArticles_start_featuredStory>
          }
          latestArticles={latestArticles}
        />
      </Section>
      <Section>
        <SymbolHeading symbol={FoodCover} symbolStyle={{ marginTop: -2 }}>
          Diets
        </SymbolHeading>
        <NutritionStartDiets diets={diets} />
      </Section>

      <Section>
        <div
          className={`
          mx-auto grid max-w-968
          gap-20 `}
        >
          <div>
            <SymbolHeading
              symbol={SaucePanHot}
              symbolStyle={{ margin: '-2 0 0 3px' }}
            >
              Featured recipes
            </SymbolHeading>
            <p className="-mt-16 mb-24 leading-body lg:-mt-24">
              Our team of professional chefs and nutritionists know how to make
              healthy eating delicious!
            </p>
            <NutritionStartRecipes
              recipes={
                featuredRecipes as NonNullableObject<LatestNutritionArticles_start_featuredRecipes>[]
              }
            />
          </div>
        </div>
      </Section>
      <Section>
        <SymbolHeading symbol={BusinessTarget} symbolStyle={{ marginLeft: 4 }}>
          What’s your goal?
        </SymbolHeading>
        <p className="-mt-16 mb-24 leading-body lg:-mt-24 lg:mb-48">
          Browse articles based on your health goal.
        </p>
        <NutritionStartGoals goals={goals} />
      </Section>
      {/*<Section>
        <div className="md:py-40 lg:pt-80">
          <AppPromo />
        </div>
      </Section>*/}
      <Section>
        <SymbolHeading symbol={Tag}>Categories</SymbolHeading>
        <NutritionStartCategories
          categories={
            categories as NonNullableObject<LatestNutritionArticles_start_categories>[]
          }
        />
      </Section>
      {/*<Section>
        <SymbolHeading symbol={Instagram}>Instagram</SymbolHeading>
        <div
          className={`
          grid grid-cols-3 grid-rows-3 gap-2
          text-center
          px-20 mx-auto max-w-512 `}
        >
          {[...Array(9)].map((_, index) => (
            <img
              key={index}
              src={`https://source.unsplash.com/random/105x105?${index}`}
              alt=""
              className="w-full"
            />
          ))}
          <div className="col-span-3 mt-24">
            <MoreButton use="a" href="https://www.instagram.com/lifesum/">
              More from @Lifesum
            </MoreButton>
          </div>
        </div>
      </Section> */}
      <NutritionFooter />
    </>
  )
}

const query = gql`
  query LatestNutritionArticles {
    latestArticles: allNutritionArticles(
      first: 4
      orderBy: _createdAt_DESC
      filter: { archived: { eq: false } }
    ) {
      id
      title
      image {
        url(
          imgixParams: { w: 240, h: 160, fm: jpg, fit: crop, crop: focalpoint }
        )
      }
      slug
    }
    start: nutritionStart {
      featuredStory {
        id
        title
        excerpt
        image {
          url(imgixParams: { w: 640, h: 384, fit: crop, crop: focalpoint })
        }
        desktopImage: image {
          url(imgixParams: { w: 640, h: 640, fit: crop, crop: focalpoint })
        }
        categories {
          name
        }
        slug
        content {
          ... on ArticleTextRecord {
            text
          }
        }
      }
      signupMessageText
      diets {
        id
        name
        slug
        image {
          url(imgixParams: { crop: focalpoint })
        }
      }
      goals {
        id
        name
        description
        slug
        image {
          url
        }
      }
      featuredRecipes {
        id
        recipeName
        slug
        image {
          responsiveImage(
            imgixParams: {
              w: 480
              h: 270
              fm: jpg
              fit: crop
              crop: focalpoint
            }
          ) {
            srcSet
            webpSrcSet
            src
            alt
            title
            base64
            width
            height
          }
        }
        intro
        time
        calories
      }
      categories {
        id
        name
        slug
      }
    }
    nutritionTime(orderBy: episodeNumber_ASC) {
      name
      description
      video {
        thumbnailUrl
        url
      }
    }
  }
`

NutritionStart.getInitialProps = () => {
  return runQuery(query)
}

export default withSSR(NutritionStart)
