import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import './styles.scss'

const linkTextClasses =
  'text-grey-darkest sm:text-grey-darkest lg:text-grey-darkest w-3/4 whitespace-nowrap hover:text-grey-dark transition'
const headingLinkTextClasses = 'quincy text-demibold pb-8 text-32'

const defaultLinks = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Meal Plans',
    href: '/meal-plans',
  },
  {
    text: 'Features',
    href: '/features',
  },
  {
    text: 'Lifesum For Work',
    href: '/work',
  },
  {
    text: 'Articles',
    href: '/nutrition-explained',
  },
]

interface LinkItemProps {
  text: string
  href: string
}

interface Props {
  hidden?: boolean
  inverted?: boolean
  showOnlyOnMobile?: boolean
  links?: LinkItemProps[]
}

const HamburgerMenu = ({
  inverted,
  hidden,
  links,
  showOnlyOnMobile = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const navItems = links || defaultLinks

  return (
    <div className={cn(showOnlyOnMobile && 'md:hidden', 'flex items-center')}>
      <button
        id="hamburgerMenu"
        className={cn('mr-24', {
          hidden: hidden,
          open: isOpen,
        })}
        onClick={() => toggleMenu()}
      >
        <span className={cn('', !inverted ? 'bg-black' : 'bg-white')}></span>
        <span className={cn('', !inverted ? 'bg-black' : 'bg-white')}></span>
        <span className={cn('', !inverted ? 'bg-black' : 'bg-white')}></span>
        <span className={cn('', !inverted ? 'bg-black' : 'bg-white')}></span>
      </button>
      <div
        className={cn(
          'menuContainer z-3 absolute top-0 overflow-hidden rounded-2 bg-white shadow-elevated sm:ml-[-16px] sm:h-screen lg:h-screen',
          isOpen ? 'sm:w-screen md:w-3/4 lg:w-1/2' : '',
        )}
      >
        <ul
          className={cn(
            'menuDrawer flex-column items-center pb-24 pt-[65px] sm:px-[16px]',
            isOpen ? 'open' : '',
          )}
        >
          {navItems.map((item) => (
            <Link key={item.href} className={linkTextClasses} to={item.href}>
              <h3 className={headingLinkTextClasses}>{item.text}</h3>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default HamburgerMenu
