import React from 'react'
import cn from 'classnames'
import Slideshow, {
  Slides,
  useSlideshow,
} from 'js/components/Slideshow/Slideshow'
import Slide from 'js/components/Slideshow/Slide'
import RecipeTeaser from './RecipeTeaser'
import Arrow from 'media/arrow-back-current-color.svg?svgr-webpack'
import { LatestNutritionArticles_start_featuredRecipes } from '../types/LatestNutritionArticles'
import { NonNullableObject } from 'typings'
import LSButton from 'lifesum-web/src/js/components/LSButton'

function SlideshowNav() {
  const { rotateBackwards, rotateForwards } = useSlideshow()

  return (
    <div className="mt-32 flex lg:mx-32">
      {['backwards', 'forwards'].map((direction) => {
        const fwd = direction === 'forwards'
        return (
          <button
            key={direction}
            onClick={fwd ? rotateForwards : rotateBackwards}
            className={cn('cursor-pointer lg:p-2', fwd && 'order-3')}
          >
            <div className="flex h-32 w-32">
              <Arrow
                className={cn(
                  'm-auto scale-75 transform text-Type transition',
                  fwd && 'rotate-180',
                )}
              />
              <span className="sr-only">
                {fwd ? 'Previous recipe' : 'Next recipe'}
              </span>
            </div>
          </button>
        )
      })}
      <LSButton
        className="order-2 mx-auto"
        link={true}
        variant="secondary"
        size="small"
        href="/nutrition-explained/recipes/"
      >
        See more recipes
      </LSButton>
    </div>
  )
}

type Props = {
  recipes: NonNullableObject<LatestNutritionArticles_start_featuredRecipes>[]
}

export default function NutritionStartRecipes({ recipes }: Props) {
  return (
    <div className="mx-auto max-w-480">
      <Slideshow>
        <Slides>
          {recipes.map((recipe) => (
            <Slide key={recipe.id}>
              <RecipeTeaser
                {...recipe}
                sizes="(min-width: 420px) 420px, 80vw"
              />
            </Slide>
          ))}
        </Slides>
        <SlideshowNav />
      </Slideshow>
    </div>
  )
}
