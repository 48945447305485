import React from 'react'

type Props = {
  symbol: React.FC<{
    className?: string
    style?: React.CSSProperties
  }>
  symbolStyle?: React.CSSProperties
  children: React.ReactNode
}

export default function SymbolHeading({ children }: Props) {
  return (
    <div className="text-center">
      <h2 className="type-med-plus-fat -mt-24 mb-24 lg:mb-40">{children}</h2>
    </div>
  )
}
